<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card class="invoice-preview-card" no-body style="padding: 1rem;">
          <!-- Header -->
          <b-tabs>
            <b-tab :title="all">
              <!-- product item -->
              <b-card
                no-body
                class="border"
                v-for="(item, index) in productsAll"
                :key="index"
              >
                <b-row class="text-center mx-0">
                  <!-- <b-col cols="5" class="border-right d-flex pl-50 py-4">
                    <b-img
                      :src="
                        item.product_Image_Id.url ||
                        require('@/assets/images/pages/eCommerce/3.png')
                      "
                      style="width: 8.5rem; height: 8.5rem;"
                    />
                  </b-col> -->

                  <b-col
                    cols="12"
                    class="border-top d-flex align-items-between flex-column py-1"
                  >
                    <b-row>
                      <b-col cols="12">
                        <div class="item-name pb-1">
                          <h6
                            class="mb-0"
                            style="font-size: 0.85rem; font-weight: bolder;"
                          >
                            {{ item.productName }}
                          </h6>
                        </div>
                        <b-row class="pb-1">
                          <b-col cols="8" style="display: flex;">
                            <b-badge
                              v-if="item.isStock"
                              pill
                              :variant="`light-${resolveStatusVariant(
                                item.isStock ? status : false
                              )}`"
                              class="text-capitalize"
                            >
                              In Stock
                            </b-badge>
                            <b-badge
                              v-else
                              pill
                              :variant="`light-${resolveStatusVariant(
                                item.isStock ? status : false
                              )}`"
                              class="text-capitalize"
                            >
                              Out Stock
                            </b-badge>
                          </b-col>
                          <b-col
                            cols="4"
                            style="
                              display: flex;
                              justify-content: end;
                              color: #0085ff;
                            "
                            >{{
                              item.productPrice.toLocaleString("en-US")
                            }}</b-col
                          >
                        </b-row>
                        <b-row class="pb-1"  v-if="userData.role !== 'แม่ทีม'">
                          <b-col cols="8" style="display: flex;">
                            จำนวน
                          </b-col>
                          <b-col
                            cols="4"
                            style="display: flex; justify-content: end;"
                            >{{
                              userData.role == "ตัวแทนขาย"
                                ? (
                                    (item.sumStockPartner || 0) -
                                    (item.realSale || 0)
                                  ).toLocaleString("en-US")
                                : item.realBalance.toLocaleString("en-US")
                            }}</b-col
                          >
                        </b-row>
                      </b-col>
                      <!-- <b-col cols="12" class="border-top">
                        <b-row class="pt-1">
                          <b-col cols="8" style="display: flex;">
                            หมวดหมู่สินค้า
                          </b-col>
                          <b-col
                            cols="4"
                            style="display: flex; justify-content: end;"
                            >{{ item.productId.productBrand }}</b-col
                          >
                        </b-row>
                      </b-col> -->
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>

            <b-tab :title="stock" style="font-size: 0.85rem;">
              <b-card
                no-body
                class="border"
                v-for="(item, index) in productHave"
                :key="index"
              >
                <b-row class="text-center mx-0">
                  <!-- <b-col cols="5" class="border-right d-flex pl-50 py-4">
                    <b-img
                      :src="
                        item.product_Image_Id.url ||
                        require('@/assets/images/pages/eCommerce/3.png')
                      "
                      style="width: 8.5rem; height: 8.5rem;"
                    />
                  </b-col> -->

                  <b-col
                    cols="12"
                    class="border-top d-flex align-items-between flex-column py-1"
                  >
                    <b-row>
                      <b-col cols="12">
                        <div class="item-name pb-1">
                          <h6
                            class="mb-0"
                            style="font-size: 0.85rem; font-weight: bolder;"
                          >
                            {{ item.productName }}
                          </h6>
                        </div>
                        <b-row class="pb-1">
                          <b-col cols="8" style="display: flex;">
                            <b-badge
                              pill
                              :variant="`light-${resolveStatusVariant(status)}`"
                              class="text-capitalize"
                            >
                              In Stock
                            </b-badge>
                          </b-col>
                          <b-col
                            cols="4"
                            style="
                              display: flex;
                              justify-content: end;
                              color: #0085ff;
                            "
                            >{{
                              item.productPrice.toLocaleString("en-US")
                            }}</b-col
                          >
                        </b-row>
                        <b-row class="pb-1"  v-if="userData.role !== 'แม่ทีม'">
                          <b-col cols="8" style="display: flex;">
                            จำนวน
                          </b-col>
                          <b-col
                            cols="4"
                            style="display: flex; justify-content: end;"
                            >{{
                              userData.role == "ตัวแทนขาย"
                                ? (
                                    (item.sumStockPartner || 0) -
                                    (item.realSale || 0)
                                  ).toLocaleString("en-US")
                                : item.realBalance.toLocaleString("en-US")
                            }}</b-col
                          >
                        </b-row>
                      </b-col>
                      <!-- <b-col cols="12" class="border-top">
                        <b-row class="pt-1">
                          <b-col cols="8" style="display: flex;">
                            หมวดหมู่สินค้า
                          </b-col>
                          <b-col
                            cols="4"
                            style="display: flex; justify-content: end;"
                            >{{ item.productId.productBrand }}</b-col
                          >
                        </b-row>
                      </b-col> -->
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab :title="productall" class="custom-tab">
              <b-card
                no-body
                class="border"
                v-for="(item, index) in productOut"
                :key="index"
              >
                <b-row class="text-center mx-0">
                  <!-- <b-col cols="5" class="border-right d-flex pl-50 py-4">
                    <b-img
                      :src="
                        item.product_Image_Id.url ||
                        require('@/assets/images/pages/eCommerce/3.png')
                      "
                      style="width: 8.5rem; height: 8.5rem;"
                    />
                  </b-col> -->
                  <b-col
                    cols="12"
                    class="border-top d-flex align-items-between flex-column py-1"
                  >
                    <b-row>
                      <b-col cols="12">
                        <div class="item-name pb-1">
                          <h6
                            class="mb-0"
                            style="font-size: 0.85rem; font-weight: bolder;"
                          >
                            {{ item.productName }}
                          </h6>
                        </div>
                        <b-row class="pb-1">
                          <b-col cols="8" style="display: flex;">
                            <b-badge
                              pill
                              :variant="`light-${resolveStatusVariant(false)}`"
                              class="text-capitalize"
                            >
                              Out Stock
                            </b-badge>
                          </b-col>
                          <b-col
                            cols="4"
                            style="
                              display: flex;
                              justify-content: end;
                              color: #0085ff;
                            "
                            >{{
                              item.productPrice.toLocaleString("en-US")
                            }}</b-col
                          >
                        </b-row>
                        <b-row class="pb-1"  v-if="userData.role !== 'แม่ทีม'">
                          <b-col cols="8" style="display: flex;">
                            จำนวน
                          </b-col>
                          <b-col
                            cols="4"
                            style="display: flex; justify-content: end;"
                            >{{
                              userData.role == "ตัวแทนขาย"
                                ? (
                                    (item.sumStockPartner || 0) -
                                    (item.realSale || 0)
                                  ).toLocaleString("en-US")
                                : item.realBalance.toLocaleString("en-US")
                            }}</b-col
                          >
                        </b-row>
                      </b-col>
                      <!-- <b-col cols="12" class="border-top">
                        <b-row class="pt-1">
                          <b-col cols="8" style="display: flex;">
                            หมวดหมู่สินค้า
                          </b-col>
                          <b-col
                            cols="4"
                            style="display: flex; justify-content: end;"
                            >{{ item.productBrand }}</b-col
                          >
                        </b-row>
                      </b-col> -->
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted, watch } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BTabs,
  BTab,
  BImg,
  BBadge,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import openbillStore from "./openbillStoreModule";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BTabs,
    BTab,
    Logo,
    BImg,
    ToastificationContent,
    BBadge,
  },
  data() {
    return {
      status: "In Stock",
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  setup() {
    if (!store.hasModule("myproduct"))
      store.registerModule("myproduct", openbillStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("myproduct")) store.unregisterModule("myproduct");
    });
    const dataUser = JSON.parse(localStorage.getItem("userData"));
    const productsAll = ref([]);
    const productOut = ref([]);
    const productHave = ref([]);
    const fetchProducts = () => {
      store
        .dispatch("myproduct/fetchMyProducts", { id: 255 })
        .then((response) => {
          productsAll.value = response.data.data;
          response.data.data.forEach((item) => {
            if (dataUser.role == "ตัวแทนขาย") {
              if ((item.sumStockPartner || 0) - (item.realSale || 0) > 0) {
                item.isStock = true;
                productHave.value.push(item);
              } else {
                item.isStock = false;
                productOut.value.push(item);
              }
            } else {
              if (item.realBalance > 0) {
                item.isStock = true;
                productHave.value.push(item);
              } else {
                item.isStock = false;
                productOut.value.push(item);
              }
            }
          });
        });
    };
    fetchProducts();
    const all = ref("ทั้งหมด(" + productsAll.value.length + ")");
    const stock = ref("ในคลัง(" + productHave.value.length + ")");
    const productall = ref("หมด(" + productOut.value.length + ")");
    watch(productsAll, () => {
      // eslint-disable-next-line no-use-before-define
      all.value = "ทั้งหมด(" + productsAll.value.length + ")";
      stock.value = "ในคลัง(" + productHave.value.length + ")";
      productall.value = "หมด(" + productOut.value.length + ")";
    });

    const resolveStatusVariant = (status) => {
      if (status === "Popular Products") return "primary";
      if (status === "In Stock" || status === "Active") return "success";
      if (status === "Recommended Products") return "info";
      if (status === false) return "danger";
      return "primary";
    };
    return {
      productsAll,
      productOut,
      productHave,
      resolveStatusVariant,
      all,
      stock,
      productall,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
}
</style>
