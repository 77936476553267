import axios, { baseURL } from '@axios'
import { paginateArray, sortCompare } from '@/common'
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {
   
  },
  getters: {},
  mutations: {},
  actions: {
    fetchMyProducts(ctx,  { id }) {
        const userData = getUserData()
  
        return new Promise((resolve, reject) => {
          axios
            .get(
              `${baseURL}/api/SubInventory/GetDapperByUserByRole?RoleId=${userData.roleId}&UserId=${userData.userId}`
            )
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    getProduct(ctx, { id }) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetById`, {
            params: {
              id,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
  
      })
    },
    addProduct(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/AddOrUpdate`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
  },
}
